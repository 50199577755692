<template>
  <div>
    <div class="card-toolbar mb-5">
      <!-- <button v-if="$can('print_setting.create')" @click="showModal" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('print_setting.add_print_setting') }}
      </button> -->
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6 mb-2 mt-2">
              <label for="f_name">{{ $t('name') }}</label>
              <input v-model="filters.name" type="text" id="f_name" class="form-control">
            </div>
            <div class="form-group col-md-6 mb-2 mt-2">
              <label for="f_type">{{ $t('print_setting.type') }}</label>
              <input v-model="filters.type" type="text" id="f_type" class="form-control">
            </div>
            <div class="form-group col-md-6 mb-2 mt-2">
              <label for="name">{{ $t('status') }}</label>
              <select name="" id="is_active" v-model="filters.is_active" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
              </select>
            </div>
            <div class="form-group d-inline-flex col-md-6 mt-10">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                <span><i class="fas fa-search"></i>
                    <span>{{ $t('search') }}</span>
                </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                  <span><i class="fas fa-trash-restore"></i>
                      <span>{{ $t('reset_search') }}</span>
                  </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="status" slot-scope="props">
            <b-form-checkbox v-if="$can('print_setting.change_status')"
                             size="lg" @change="changeStatus(props.row.id, props.row.is_active)"
                             v-model="props.row.is_active"
                             :name="'check-button'+props.row.id"
                             switch :key="props.row.id">
            </b-form-checkbox>
            <b-form-checkbox v-else
                             size="lg" :disabled="true"
                             v-model="props.row.is_active"
                             :name="'check-button'+props.row.id"
                             switch :key="props.row.id">
            </b-form-checkbox>
          </template>
          <template slot="actions" slot-scope="props">
            <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('print_setting.update')" @click="showModal(props.row)">mdi-pencil</v-icon>
            <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('print_setting.delete')" @click="deleteprint_setting(props.row)">mdi-delete</v-icon>
            <b-dropdown :id="'dropdown-offset_'+props.row.id" :text="$t('actions') " variant="outline-primary btn-sm" class="m-2">

              <router-link class="dropdown-item" :to="`/settings/print-settings-details/${props.row.id}/${props.row.type}`">{{ $t('print_setting.print_settings_details') }}</router-link>
              <a href="javascript:;" class="dropdown-item" @click="resetDefaultValues(props.row.type)">{{$t('reset_default_values')}}</a>

            </b-dropdown>


          </template>
        </v-server-table>
        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->

    <b-modal ref="modal" hide-footer :title="$t('print_setting.print_setting')">
      <div class="row">
        <div class="col-lg-6 mb-5">
          <label for="name">
            {{ $t('print_setting.name') }}<span class="text-danger">*</span>
          </label>
          <input v-model="print_setting.name" disabled type="text" id="name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''">
          <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
              {{ validation.name[0] }}
          </span>
        </div>
        <div class="col-md-6 mb-5">
          <label for="name">
            {{ $t('print_setting.type') }}
          </label>
          <!-- <multiselect v-model="type_name"
                       id="type"
                       :placeholder="$t('print_setting.type')"
                       label="title"
                       track-by="id"
                       :options="type_list"
                       :multiple="false"
                       :taggable="false"
                       :show-labels="false"
                       :show-no-options="false"
                       :show-no-results="false">
          </multiselect> -->
          <div class="input-group">
            <select name="" id="type" v-model="print_setting.type" class="custom-select" :class="validation && validation.type ? 'is-invalid' : ''">
              <option v-for="row in type_list" :value="row.id" :key="row.id">
                {{ row.title }}
              </option>
            </select>
                    
          <span v-if="validation && validation.type" class="fv-plugins-message-container invalid-feedback">
              {{ validation.type[0] }}
          </span>
        </div>
        </div>
        <!-- <div class="col-lg-6 mb-5">
          <label for="name">
            {{ $t('print_setting.key') }}
          </label>
          <input v-model="print_setting.key" disabled type="text" id="key" class="form-control">
        </div> -->

        <div class="col-lg-6 mb-5">
          <label>{{ $t('print_setting.upload_attachment') }}</label>
          <div class="form-group">
            <upload-file
                @file="listenerAttachment"
                :inner-id="'attachment'"
                :placeholder="$t('print_setting.upload_attachment')"
                :upload="dir_upload"
                :start-link="'base'"
                v-bind:valueprop="{name:'image',path:attachment_url}" v-if="reloadUploadAttachment">
            </upload-file>
          </div>
        </div>

        <div class="col-lg-6 mb-5">
          <label>{{ $t('status') }}:</label>
          <b-form-checkbox size="lg" v-model="print_setting.is_active" name="check-button" switch></b-form-checkbox>
        </div>
      </div>


      <div class="d-flex justify-content-end">
        <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{ $t('close') }}</b-button>
        <b-button class="mt-2 mr-1" variant="primary" @click="save">{{ $t('save') }}</b-button>
      </div>
    </b-modal>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "index",
  components: {},
  data() {
    return {
      print_setting: {
        name: '',
        type: null,
        is_active: true,
        attachment: null,
      },
      showAdvancedSearch: false,
      filter: {
        sortBy: 'id',
      },
      filters: {
        name: '',
        type: '',
        is_active: '',
      },
      columns: ['name', 'type_name', 'header_temp_name', 'created_at', 'status', 'actions'],
      validation: null,
      mainRoute: 'settings/print_settings',
      routeChangeStatus: 'settings/print_setting/change-status',
      mainRouteResetDefaultValues : 'settings/print_setting/reset_print_setting',
      isEditing: false,
      id: null,
      type_list: [],
      type_name: null,
      reloadUploadAttachment: true,
      attachment_url: null,
      dir_upload: 'printSetting',

      status_list: [
        {id: 1, text: this.$t('active')},
        {id: 0, text: this.$t('inactive')},
      ],
    }
  },
  watch: {
    type_name: function (val) {
      if (val) {
        this.print_setting.type = val;
      } else {
        this.print_setting.type = null;
      }
    },
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          name: that.$t('print_setting.name'),
          type_name: that.$t('print_setting.type'),
          header_temp_name: that.$t('print_setting.template'),
          created_at: that.$t('created_at'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',
        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.print_settings")}]);
    this.getTypeList();
  },
  methods: {

    listenerAttachment(event) {
      if (event) {
        this.attachment_url = event.pathDB;
        this.print_setting.attachment = event.name;
      }
    },

    changeStatus(id, status) {
      ApiService.patch(this.routeChangeStatus + '/' + id, {
        is_active: (status ? 1 : 0),
      }).then((response) => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.$errorAlert(error);
      });
    },

    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.name = '';
      this.filters.type = '';
      this.filters.is_active = '';
      this.$refs.table.refresh();
    },
    save() {
      if (this.isEditing) {
        this.updateprint_setting();
      } else {
        this.savePrintSetting();
      }
    },
    getFetch() {
      this.$refs.table.refresh();
    },
    actionDelete(print_setting) {
      ApiService.delete(`${this.mainRoute}/${print_setting.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
    deleteprint_setting(print_setting) {
      this.$confirmAlert('', this.actionDelete, print_setting);
    },

    showModal(data) {
      this.reset();
      this.validation = null;
      if (!data.id) {
        this.isEditing = false;
        this.id = null;
        this.$refs['modal'].show()
      } else {
        this.isEditing = true;
        this.id = data.id;
        this.getData(data);
        this.$refs['modal'].show();
      }
    },
    hideModal() {
      this.reset();
      this.$refs['modal'].hide()
    },
    toggleModal() {
      this.$refs['modal'].toggle('#toggle-btn')
    },
    savePrintSetting() {
      ApiService.post(this.mainRoute, this.print_setting).then((response) => {
        this.$successAlert(response.data.message);
        this.afterSavePrintSetting();
      }).catch((errors) => {
        this.validation = errors.response ? errors.response.data.errors : null;
      });
    },
    updateprint_setting() {
      ApiService.put(`${this.mainRoute}/${this.id}`, {
        ...this.print_setting,
      }).then((response) => {
        this.$successAlert(response.data.message);
        this.validation = null;
        this.afterSavePrintSetting();
      })
          .catch((errors) => {
            this.validation = errors.response ? errors.response.data.errors : null;
          });
    },
    getData(data) {
      console.log(data);
      this.reloadUploadAttachment = false;
      this.print_setting.name = data.name;
      this.print_setting.type = data.type;
      this.type_name = data.type;
      this.print_setting.is_active = data.is_active;
      this.print_setting.attachment = data.attachment;
      this.attachment_url = data.attachment_url;

      this.reloadUploadAttachment = true;
    },

  getTypeList() {
      ApiService.get('base/dependency/print_type_list').then(response => {
        this.type_list = response.data.data;
      });
    },

    reset() {
      this.print_setting = {
        name: '',
        type: '',
        is_active: true
      };
      this.validation = [];
    },

    afterSavePrintSetting() {
      this.print_setting = {
        name: '',
        type: '',
        is_active: true
      };
      this.hideModal();
      this.getFetch();
    },
    resetDefaultValues(type){
      this.$confirmAlert(this.$t('reset_default_values_alert_message'), this.actionResetDefaultValues, type);
    },
    actionResetDefaultValues(type){
      ApiService.patch(`${this.mainRouteResetDefaultValues}/${type}`).then(response => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      });
    },
  },
};
</script>
